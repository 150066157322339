import React, { useState, useEffect } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Form, useForm } from '../../../applications/hooks/UseForm'
import Controls from '../../../components/Entry/Controls'
import Notification from '../../../layouts/Notification'
import PlantingService from '../services/Planting'
import PlantingSpeciesCard from '../card/CardPlantingSpecies'
import { Grid } from '@mui/material'
import Subtitle from '../../../components/Typography/Subtitle'
import LandService from '../../mangrove_land/services/Land'
import { LoadScript } from '@react-google-maps/api'
import Map from '../../../components/Entry/Map'

import ProvinceService from '../../provinces/services/Provinces'
import DataSetsService from '../../datasets/services/DataSets'
import SpeciesService from '../../species/services/Species'
import QrViewer from '../../../components/Entry/QrViewer'
import { useAuth } from '../../../applications/hooks/UseAuth'
import { convertHaToM2, convertM2ToHa } from '../../../applications/UtilFunctions'
import CustomAccordion from '../../../components/Accordion/CustomAccordion'
import { FaTrashAlt } from 'react-icons/fa'
import Popup from '../../../layouts/Popup'
import UploadTrackingPoints from './popupView/UploadTrackingPoints'
import VillageAutoCompleteSection from '../../provinces/components/VillageAutoCompleteSection'

const useStyles = {
    cardContainer: {
        backgroundColor: '#f8f8f8',
        border: 'none',
        boxShadow: 'none',
        width: '90%',
        marginTop: '20px'
    },
    card: {
        width: '25%',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    title: {
        padding: '20px 20px 20px 20px',
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    subtitle: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none'
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}

const { Input: TextField, CustomSelect } = Controls

const initialFValues = {
    land: '',
    session: '',
    plantation_type: '',
    planting_date_begin: '',
    planting_date_end: '',
    surface: '',
    site: ''
}

const styles = {
    form_title: {
        padding: `10px 0px 20px 0px `,
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        color: '#000000',
        fontSize: '0.8rem',
        fontWeight: 'bold'
    }
}

export const PlantingForm = (props) => {
    const { records, dataId } = props
    const classes = useStyles
    const isDisabled = !!Object.keys(records).length

    const [plantationTypeOption, setPlantationTypeOption] = useState([])
    const [originOption, setOriginOption] = useState([])
    const [plantingSessionOption, setPlantingSessionOption] = useState([])
    const [speciesDetails, setSpeciesDetails] = useState({})
    const [userHaveToUploadTrackingPoints, setUserHaveToUploadTrackingPoints] = useState(false)

    const ProvinceApi = ProvinceService.load()
    const dataset = DataSetsService.load()

    const navigate = useNavigate()

    const [species, setSpecies] = useState([])
    const [land, setLand] = useState([])
    const { user } = useAuth()

    const initialOption = []

    const [groups, setGroups] = useState(initialOption)
    const [groupSpecies, setGroupSpecies] = useState([])
    const [grid, setGrid] = useState(null)
    const [userHaveToPost, setUserHaveToPost] = useState(false)

    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('plantation_type' in fieldValues)
            temp.plantation_type = fieldValues.plantation_type.length !== 0 ? '' : 'Ce champ est requis.'
        if ('session' in fieldValues) temp.session = fieldValues.session ? '' : 'Ce champ est requis.'
        if ('land' in fieldValues) temp.land = fieldValues.land.length !== 0 ? '' : 'Ce champ est requis.'
        if ('site' in fieldValues) temp.site = fieldValues.site ? '' : 'Ce champ est requis.'
        if ('surface' in fieldValues) temp.surface = fieldValues.surface ? '' : 'Ce champ est requis.'
        if ('planting_date_begin' in fieldValues)
            temp.planting_date_begin = fieldValues.planting_date_begin ? '' : 'Ce champ est requis.'
        if ('planting_date_end' in fieldValues)
            temp.planting_date_end = fieldValues.planting_date_end ? '' : 'Ce champ est requis.'
        setErrors({
            ...temp
        })

        if (fieldValues === values) return Object.values(temp).every((x) => x === '')
    }

    const { values, setValues, errors, setErrors, handleInputChange } = useForm(initialFValues, true, validate)

    useEffect(() => {
        if (records) {
            if (records?.grid) {
                setGrid([
                    {
                        id: 1,
                        coordinates: records?.grid?.coordinates
                    }
                ])
            }
            setValues((prevFormData) => ({
                ...prevFormData,
                surface: convertM2ToHa(records?.surface) || '',
                session: records?.session || '',
                origin: records?.origin || '',
                customer: records?.customer || '',
                plantation_type: records?.plantation_type || '',
                planting_date_begin: records?.planting_date_begin
                    ? moment(records.planting_date_begin).format('YYYY-MM-DD')
                    : '',
                planting_date_end: records?.planting_date_end
                    ? moment(records.planting_date_end).format('YYYY-MM-DD')
                    : '',
                polygon_grid: records?.polygon_grid || '',
                //species: records?.specie?.id || '',
                land: records?.land?.code || ''
            }))
        }
    }, [records, setValues])

    useEffect(() => {
        dataset
            .getDataCode('mango_plantation_type')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setPlantationTypeOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
        dataset
            .getDataCode('mangrove_origin')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setOriginOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })

        console.log(new Date().toISOString())

        dataset
            .getDataCode('planting_session')
            .then((response) => {
                if (response?.data) {
                    const dataArray = JSON.parse(response.data[0].database)
                    setPlantingSessionOption(dataArray)
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
    }, [ProvinceApi, dataset])

    useEffect(() => {
        if (values.site) {
            LandService.getAllLand(values.site).then((res) => {
                setLand(res?.data)
            })
            ProvinceApi.getMangroGroupe(values.site).then((res) => {
                setGroups(res?.data)
            })
        }
    }, [values.site, ProvinceApi])

    const handleSubmit = async (e) => {
        console.log('values', values)
        console.log(groups)
        e.preventDefault()
        if (validate()) {
            try {
                setUserHaveToPost(true)
                if (groups.find((item) => item.checked === true)) {
                    const dataToSend = { ...values, user: [user.firstname, user.lastname].join(' ') }
                    delete dataToSend.region
                    delete dataToSend.district
                    delete dataToSend.commune
                    delete dataToSend.fkt
                    delete dataToSend.village
                    delete dataToSend.site
                    delete dataToSend.land
                    delete dataToSend.customer
                    // dataToSend.specieId = specieId
                    dataToSend.land_id = values.land
                    dataToSend.surface = convertHaToM2(values.surface)
                    dataToSend.groups = groupSpecies
                    let message = ''
                    let plantation = null
                    console.log(dataToSend)
                    if (records?.id) {
                        plantation = await PlantingService.updatePlanting(records.id, dataToSend)
                        message = PlantingService.messages.update_success
                    } else {
                        // save plantation
                        plantation = await PlantingService.addPlanting(dataToSend, groups, specieValues)
                        message = PlantingService.messages.create_success
                    }

                    if (plantation) {
                        new Notification(message, 1)
                        setUserHaveToPost(false)
                        navigate(-1)
                    } else {
                        new Notification(message, 0)
                        setUserHaveToPost(false)
                    }
                } else {
                    setUserHaveToPost(true)
                    new Notification('Veuillez sélectionner au moins un groupe', 0)
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleSelectChange = (e) => {
        handleInputChange(e)
    }

    const [specieArray, setSpecieArray] = useState([])
    const [specieValues, setSpecieValues] = useState([])

    useEffect(() => {
        SpeciesService.getAllSpecies().then((specie) => {
            if (specie) {
                console.log(specie)
                setSpecieArray(specie)
            }
        })
    }, [])

    const onCheckboxChange = (e) => {
        if (e.target.value === true) {
            groups[e.target.name] = {
                ...groups[e.target.name],
                checked: true
            }
            console.log(groups)
            setGroupSpecies([...groupSpecies, { id: groups[e.target.name].id }])
            setGroups(groups)
        } else {
            groups[e.target.name] = {
                ...groups[e.target.name],
                checked: false
            }
            console.log(groups)
            delete specieValues[groups[e.target.name].id]
            setGroupSpecies(groupSpecies.filter((item) => item.id !== groups[e.target.name].id))
            setSpecieValues(specieValues)
            setGroups(groups)
        }
        setSpecies(groups.filter((item) => item.checked === true))
    }

    const handleSpecieChange = (e) => {
        const [key, group] = e.target.name.split('_')
        switch (key) {
            case 'specie':
                setSpecieValues({ ...specieValues, [group]: { ...specieValues[group], specie: e.target.value } })
                break
            case 'seed':
                setSpecieValues({ ...specieValues, [group]: { ...specieValues[group], seed: e.target.value } })
                break
            case 'propagule':
                setSpecieValues({ ...specieValues, [group]: { ...specieValues[group], propagule: e.target.value } })
                break
            case 'youngplant':
                setSpecieValues({ ...specieValues, [group]: { ...specieValues[group], youngplant: e.target.value } })
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (groupSpecies) {
            console.log(groupSpecies)
        }
    }, [groupSpecies])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={dataId === 'new' ? 12 : 8} mb={3}>
                    <Container>
                        {!isDisabled && (
                            <Container>
                                <VillageAutoCompleteSection
                                    values={values}
                                    setValues={setValues}
                                    errors={errors}
                                    onChange={handleInputChange}
                                    isRequired={true}
                                />
                            </Container>
                        )}
                        <Grid container>
                            <Container>
                                <Typography sx={styles.form_title}>Information plantation</Typography>
                            </Container>
                            <Grid item xs={6}>
                                <Container>
                                    {/* <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="session"
                                        value={values?.session}
                                        size="small"
                                        label="Session de plantation"
                                        onChange={handleInputChange}
                                        error={errors.session}
                                        disabled={isDisabled}
                                        isRequired={true}
                                    /> */}
                                    <Controls.AutoCompleteSelect
                                        label="Session de plantation"
                                        name="session"
                                        value={values?.session || ''}
                                        options={plantingSessionOption}
                                        onChange={handleSelectChange}
                                        error={errors.session}
                                        labelKey="label"
                                        valueKey="label"
                                        isDisabled={isDisabled}
                                        isRequired={true}
                                    />
                                    {isDisabled ? (
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="land"
                                            value={values?.land + '/' + records?.land?.site?.name}
                                            size="small"
                                            label="Parcelle"
                                            disabled={isDisabled}
                                            isRequired={true}
                                        />
                                    ) : (
                                        <Controls.AutoCompleteSelect
                                            label="Parcelle"
                                            name="land"
                                            value={values?.land}
                                            onChange={handleSelectChange}
                                            options={land || []}
                                            error={errors.land}
                                            labelKey="code"
                                            valueKey="id"
                                            isRequired={true}
                                        />
                                    )}
                                    <>
                                        <Controls.AutoCompleteSelect
                                            label="Provenance"
                                            name="origin"
                                            value={values?.origin || ''}
                                            options={originOption}
                                            onChange={handleSelectChange}
                                            error={errors.origin}
                                            labelKey="label"
                                            valueKey="label"
                                            isDisabled={isDisabled}
                                            disableSorting={true}
                                        />
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={values?.surface}
                                            size="small"
                                            label="Superficie plantées (ha)"
                                            name="surface"
                                            onChange={handleInputChange}
                                            error={errors.surface}
                                            disabled={isDisabled}
                                            type="number"
                                            isRequired={true}
                                        />
                                    </>
                                </Container>
                            </Grid>
                            <Grid item xs={6}>
                                <Container>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="polygon_grid"
                                        value={values?.polygon_grid}
                                        size="small"
                                        label="Polygone de quadrillage"
                                        onChange={handleInputChange}
                                        error={errors.polygon_grid}
                                        disabled={isDisabled}
                                    />
                                    {isDisabled ? (
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            name="plantation_type"
                                            value={values?.plantation_type}
                                            size="small"
                                            label="Type de plantation"
                                            disabled={isDisabled}
                                            isRequired={true}
                                        />
                                    ) : (
                                        <Controls.AutoCompleteSelect
                                            label="Type de plantation"
                                            name="plantation_type"
                                            value={values?.plantation_type || ''}
                                            options={plantationTypeOption}
                                            onChange={handleSelectChange}
                                            error={errors.plantation_type}
                                            labelKey="label"
                                            valueKey="label"
                                            disabled={isDisabled}
                                            isRequired={true}
                                        />
                                    )}
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="customer"
                                        value={values?.customer}
                                        size="small"
                                        label="Client"
                                        disabled={true}
                                    />
                                </Container>
                            </Grid>
                        </Grid>

                        <Container>
                            <div className="border border-gray-300 rounded w-[90%] items-center">
                                <Container>
                                    <div style={{ paddingTop: '10px' }}>Date de plantation</div>
                                </Container>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Container>
                                        {/* <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={values?.planting_date_begin}
                                            size="small"
                                            // label="Superficie plantées"
                                            name="planting_date_begin"
                                            onChange={handleInputChange}
                                            error={errors.planting_date_begin}
                                            type="date"
                                            disabled={isDisabled}
                                            isRequired={true}
                                        /> */}
                                        <Controls.DatePicker
                                            name="planting_date_begin"
                                            onChange={handleInputChange}
                                            value={values?.planting_date_begin}
                                            error={errors?.planting_date_begin}
                                            isRequired={true}
                                            isDisabled={isDisabled}
                                        />
                                    </Container>
                                    <div className=" font-semibold mt-[20px]">au</div>
                                    <Container>
                                        {/* <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={values?.planting_date_end}
                                            size="small"
                                            name="planting_date_end"
                                            onChange={handleInputChange}
                                            error={errors.planting_date_end}
                                            type="date"
                                            disabled={isDisabled}
                                            isRequired={true}
                                        /> */}
                                        <Controls.DatePicker
                                            name="planting_date_end"
                                            onChange={handleInputChange}
                                            value={values?.planting_date_end}
                                            error={errors?.planting_date_end}
                                            isRequired={true}
                                            isDisabled={isDisabled}
                                        />
                                    </Container>
                                </div>
                            </div>
                        </Container>
                        <Container>
                            {!isDisabled && (
                                <div style={classes.cardContainer}>
                                    <div style={classes.title}>
                                        <Subtitle
                                            style={{
                                                fontSize: '0.8rem',
                                                color: '#677788'
                                            }}
                                        >
                                            Choisir les groupes et les espèces pour cette plantation
                                        </Subtitle>
                                    </div>
                                    <Container>
                                        {groups.map((group, key) => {
                                            return (
                                                <div style={{ display: 'flex', paddingBottom: '10px' }} key={key}>
                                                    <div>
                                                        <Controls.Checkbox
                                                            name={key}
                                                            onChange={onCheckboxChange}
                                                            label={group.name}
                                                        />
                                                    </div>
                                                    {group?.checked && (
                                                        <div style={{ width: '80%' }}>
                                                            <CustomAccordion title={`Espèces pour ${group.name}`}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={4}>
                                                                        <Controls.AutoCompleteSelect
                                                                            label="Espèce"
                                                                            name="id"
                                                                            value={speciesDetails?.id || ''}
                                                                            options={specieArray}
                                                                            onChange={(e) => {
                                                                                setSpeciesDetails({
                                                                                    ...speciesDetails,
                                                                                    id: e.target.value
                                                                                })
                                                                            }}
                                                                            labelKey="name"
                                                                            valueKey="id"
                                                                        />
                                                                        <TextField
                                                                            name="seed"
                                                                            value={speciesDetails?.seed || ''}
                                                                            onChange={(e) => {
                                                                                setSpeciesDetails({
                                                                                    ...speciesDetails,
                                                                                    seed: e.target.value
                                                                                })
                                                                            }}
                                                                            label="Graines"
                                                                            type="number"
                                                                        />
                                                                        <TextField
                                                                            name="propagule"
                                                                            value={speciesDetails?.propagule || ''}
                                                                            type="number"
                                                                            label="Propagules"
                                                                            onChange={(e) => {
                                                                                setSpeciesDetails({
                                                                                    ...speciesDetails,
                                                                                    propagule: e.target.value
                                                                                })
                                                                            }}
                                                                        />
                                                                        <TextField
                                                                            name="youngplant"
                                                                            value={speciesDetails?.youngplant || ''}
                                                                            type="number"
                                                                            label="Jeunes plants"
                                                                            onChange={(e) => {
                                                                                setSpeciesDetails({
                                                                                    ...speciesDetails,
                                                                                    youngplant: e.target.value
                                                                                })
                                                                            }}
                                                                        />
                                                                        <Controls.Button
                                                                            text="Ajouter"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                console.log(speciesDetails)
                                                                                let newGroupSpecies = groupSpecies
                                                                                console.log(newGroupSpecies)
                                                                                newGroupSpecies.map((item) => {
                                                                                    if (item.id === group.id) {
                                                                                        if (item.species) {
                                                                                            item.species = [
                                                                                                ...item?.species,
                                                                                                speciesDetails
                                                                                            ]
                                                                                        } else {
                                                                                            item.species = [
                                                                                                speciesDetails
                                                                                            ]
                                                                                        }
                                                                                    }
                                                                                    return item
                                                                                })

                                                                                console.log(newGroupSpecies)
                                                                                setGroupSpecies([...newGroupSpecies])
                                                                                setSpeciesDetails({})
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <div
                                                                            style={{
                                                                                overflowY: 'scroll',
                                                                                height: '250px',
                                                                                border: '1px solid #ccc',
                                                                                padding: '10px',
                                                                                borderRadius: '5px',
                                                                                //add style to the scrollbar
                                                                                '&::-webkit-scrollbar': {
                                                                                    width: '5px',
                                                                                    backgroundColor: 'transparent'
                                                                                },
                                                                                '&::-webkit-scrollbar-thumb': {
                                                                                    backgroundColor: '#ccc',
                                                                                    borderRadius: '5px'
                                                                                }
                                                                            }}
                                                                        >
                                                                            {groupSpecies
                                                                                .find((item) => item.id === group.id)
                                                                                ?.species?.map((item, key) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={key}
                                                                                            style={{
                                                                                                marginBottom: '10px',
                                                                                                border: '1px solid #ccc',
                                                                                                padding: '10px',
                                                                                                borderRadius: '5px'
                                                                                            }}
                                                                                        >
                                                                                            <Subtitle
                                                                                                style={{
                                                                                                    fontSize: '0.75rem',
                                                                                                    color: '#677788',
                                                                                                    display: 'flex',
                                                                                                    justifyContent:
                                                                                                        'space-between'
                                                                                                }}
                                                                                            >
                                                                                                {key + 1} -{' '}
                                                                                                {
                                                                                                    specieArray.find(
                                                                                                        (specie) =>
                                                                                                            specie.id ===
                                                                                                            item.id
                                                                                                    )?.name
                                                                                                }
                                                                                                <FaTrashAlt
                                                                                                    style={{
                                                                                                        cursor: 'pointer',
                                                                                                        color: 'red',
                                                                                                        alignSelf:
                                                                                                            'center'
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        let newGroupSpecies =
                                                                                                            groupSpecies
                                                                                                        newGroupSpecies.map(
                                                                                                            (
                                                                                                                itemMaped
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    itemMaped.id ===
                                                                                                                    group.id
                                                                                                                ) {
                                                                                                                    itemMaped.species =
                                                                                                                        itemMaped.species.filter(
                                                                                                                            (
                                                                                                                                specie
                                                                                                                            ) =>
                                                                                                                                specie.id !==
                                                                                                                                item.id
                                                                                                                        )
                                                                                                                }
                                                                                                                return itemMaped
                                                                                                            }
                                                                                                        )

                                                                                                        console.log(
                                                                                                            newGroupSpecies
                                                                                                        )
                                                                                                        setGroupSpecies(
                                                                                                            [
                                                                                                                ...newGroupSpecies
                                                                                                            ]
                                                                                                        )
                                                                                                    }}
                                                                                                />
                                                                                            </Subtitle>
                                                                                            <Typography
                                                                                                variant="body2"
                                                                                                color="text.secondary"
                                                                                                style={{
                                                                                                    fontSize: '0.7rem'
                                                                                                }}
                                                                                            >
                                                                                                <div>
                                                                                                    Graine :{' '}
                                                                                                    {item?.seed}
                                                                                                </div>
                                                                                                <div>
                                                                                                    Propagule :{' '}
                                                                                                    {item?.propagule}
                                                                                                </div>
                                                                                                <div>
                                                                                                    Jeune Plant :{' '}
                                                                                                    {item?.youngplant}
                                                                                                </div>
                                                                                            </Typography>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                                .reverse()}
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </CustomAccordion>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </Container>
                                </div>
                            )}
                            {isDisabled && <PlantingSpeciesCard plantedSpecies={records?.planting_group} />}
                        </Container>
                    </Container>
                </Grid>
                <Grid item xs={4} mt={2}>
                    <Container>
                        {dataId !== 'new' && (
                            <>
                                <div className="text-xs"> Localisation </div>
                                {records?.grid && !Array.isArray(records?.grid) ? (
                                    <LoadScript>
                                        <Map data={grid} zoom={10} />
                                    </LoadScript>
                                ) : (
                                    <h5 style={{ paddingTop: '10px' }}>(Localisation non disponible.)</h5>
                                )}
                                <div className="mt-[20px]">
                                    <div className="text-xs "> Photos </div>
                                    <div className="  flex items-center mt-[10px] w-[100px] h-[100px]">
                                        <Controls.CustomImageAvatar
                                            title="Photo"
                                            src={'vide'}
                                            size={100}
                                            place="left"
                                        />
                                        {/* <img src={plt} alt="image profil" className="w-full h-full" /> */}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        marginTop: '20px'
                                    }}
                                >
                                    <Controls.Button
                                        text="Importer points de suivi"
                                        haveSpin={true}
                                        size="large"
                                        disabled={userHaveToPost}
                                        onClick={() => {
                                            setUserHaveToUploadTrackingPoints(true)
                                        }}
                                    />
                                </div>
                                <Box sx={{ paddingTop: '40px' }}>
                                    <Container>
                                        <QrViewer value={records?.code_qr} size={'60%'} />
                                    </Container>
                                </Box>
                            </>
                        )}
                    </Container>
                </Grid>
            </Grid>

            {!isDisabled && (
                <div className="flex justify-end items-end m-5 p-5">
                    <Controls.Button type="submit" text="Enregistrer" haveSpin={true} disabled={userHaveToPost} />
                </div>
            )}

            <Popup
                title="Importer points de suivi"
                openPopup={userHaveToUploadTrackingPoints}
                setOpenPopup={setUserHaveToUploadTrackingPoints}
            >
                <UploadTrackingPoints setUserHaveToUploadTrackingPoints={setUserHaveToUploadTrackingPoints} />
            </Popup>
        </Form>
    )
}
